import React, {useState, useEffect, useContext} from 'react'
import s from './SideBar.module.scss'
import textLogo from 'Static/Logo/nxstep-white.svg';
import {clearSessionCookie, isLoggedIn} from 'Utils/sessions'
import {RiDashboardFill, RiCompassDiscoverLine,
    RiMoneyDollarCircleFill, RiSettings3Line, RiLogoutBoxLine,
    RiCalendar2Fill, RiMailFill, RiUser3Line, RiBriefcase2Line, RiUser3Fill, RiNewspaperLine,
    RiLoginBoxLine, RiGiftLine} from 'react-icons/ri';
import {AiOutlineForm} from 'react-icons/ai';
import { FaCircle } from 'react-icons/fa';
import {Root} from 'Root'
import {SessionContext, isMentor, isMentee, isPartner, isEmployer} from 'Utils/sessions'
import { Sidebar, SidebarItem } from 'react-responsive-sidebar';
import {SearchBar} from 'Components/Input/Search'
import {FiSearch, FiUsers} from 'react-icons/fi'
import Notifications from './Notifications'
import { FaGraduationCap } from 'react-icons/fa';
import avi from 'Static/default-avi.png';

const UnreadMessageIndicator = ({}) => {
  const {session, refreshSession} = useContext(SessionContext);
  const [notifs, setNotifs] = useState(null)

  useEffect(() => {
    if (isLoggedIn()) {
      Root.miscApi.getUnreadMessageCount(session.id)
      .then((rsp) => {
        if (typeof rsp.data === 'number' && !Number.isNaN(rsp.data) && rsp.data > 0) {
          setNotifs(rsp.data)
        } else {
          setNotifs(null)
        }
      })
      .catch((err)=> {
        setNotifs(null)
      })
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn()) {
      navigator.serviceWorker.addEventListener('message', event => {
        if (event.data.type === 'notification-received') {
          Root.miscApi.getUnreadMessageCount(session.id)
          .then((rsp) => {
            if (typeof rsp.data === 'number' && !Number.isNaN(rsp.data) && rsp.data > 0) {
              setNotifs(rsp.data)
            } else {
              setNotifs(null)
            }
          })
          .catch((err)=> {
            setNotifs(null)
          })
        }
      });

      return () => {
        navigator.serviceWorker.removeEventListener('message', handleMessage);
      };
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn()) {
      document.addEventListener('new-notification-received', (event) => {
        Root.miscApi.getUnreadMessageCount(session.id)
        .then((rsp) => {
          if (typeof rsp.data === 'number' && !Number.isNaN(rsp.data) && rsp.data > 0) {
            setNotifs(rsp.data)
          } else {
            setNotifs(null)
          }
        })
        .catch((err)=> {
          setNotifs(null)
        })
      });

      document.addEventListener('thread-marked-read', (event) => {
        Root.miscApi.getUnreadMessageCount(session.id)
        .then((rsp) => {
          if (typeof rsp.data === 'number' && !Number.isNaN(rsp.data) && rsp.data > 0) {
            setNotifs(rsp.data)
          } else {
            setNotifs(null)
          }
        })
        .catch((err)=> {
          setNotifs(null)
        })
      });
    }
  }, []);

  const handleMessage = (event) => {
    if (event.data.type === 'push-received') {
      console.log('Message from service worker:', event.data.message);
      // Perform actions in your app based on the message
      alert(event.data.message)
    }
  }

  return <>
    {notifs && <span size={12} className={'ms-2'}>({notifs})</span>}
    </>
}

const Header = ({title}) => {
    const {session, refreshSession} = useContext(SessionContext);
    const redirectProfile = () => {
        if(isMentor(session)){
            window.location.href = "/profile/student/" + session.userName
        } else if (isMentee(session)) {
            window.location.href = "/profile/applicant/" + session.userName
        }
    }
    return <><div className={s.header}>
        <h2 className={s.title}>{title}</h2>

        <SearchBar containerClassName={s.searchContainer} text={<FiSearch/>} placeholder={'Search...'} />

        {session.id && <span className={`d-flex align-items-center justify-content-end`}>
            <Notifications className={s.notifications}/>
            <span className={`d-flex align-items-center ${s.profile}`} onClick={redirectProfile}>
                <div className={s.avi} style={{backgroundImage: `url(${session.photo || avi})`, backgroundSize:'cover'}} />
                <div className='ms-2 trunc'>
                    <span><strong>{session.firstName} {session.lastName}</strong></span>
                    <span className='text-muted d-block'>@{session.userName}</span>
                </div>
            </span>
        </span>}
    </div>
    </>
}

const SideBarContainer = ({session, title, ...props}) => {
    const redirectProfile = () => {
        if(isMentor(session)){
            window.location.href = "/profile/student/" + session.userName
        } else if (isMentee(session)) {
            window.location.href = "/profile/applicant/" + session.userName
        }
    }
    const items = [
        <SidebarItem href={isEmployer(session) ? '/dashboard/employer' : '/dashboard/student'}><img
        alt=""
        src={textLogo}
        height="35"
        className="d-inline-block align-center"
        /></SidebarItem>,
    ]

    if(!isLoggedIn()) {
        items.push(
        <SidebarItem href='/discover'><span className={s.link}><RiCompassDiscoverLine/>{' '}Discover</span></SidebarItem>,
        <SidebarItem href='/jobs?pg=1'><span className={s.link}><RiBriefcase2Line/>{' '}Job Search</span></SidebarItem>,
        <SidebarItem href='/register'><span className={s.link}><AiOutlineForm/>{' '}Register</span></SidebarItem>,
        <SidebarItem href='/login'><span className={s.link}><RiLoginBoxLine/>{' '}Login</span></SidebarItem>,
        )
    }

    if(session && session.id && (isMentor(session) || isMentee(session))) {
        items.push(
        <SidebarItem href='/dashboard/student'><span className={s.link}><RiDashboardFill/>{' '}Dashboard</span></SidebarItem>,
        <SidebarItem href='/discover'><span className={s.link}><RiCompassDiscoverLine/>{' '}Discover</span></SidebarItem>,
        <SidebarItem href='/schedule'><span className={s.link}><RiCalendar2Fill/> {' '}Schedule</span></SidebarItem>,
        <SidebarItem href='/inbox'><span className={s.link}><RiMailFill/>{' '}Inbox<UnreadMessageIndicator/></span></SidebarItem>,
        <SidebarItem href='/invites'><span className={s.link}><RiGiftLine/>{' '}Invite Students</span></SidebarItem>,
        <SidebarItem href='/payment'><span className={s.link}><RiMoneyDollarCircleFill/>{' '}Payment</span></SidebarItem>,
        <SidebarItem href='/settings'><span className={s.link}><RiSettings3Line/>{' '}Settings</span></SidebarItem>,
        <SidebarItem><span onClick={redirectProfile} className={s.link}><RiUser3Line/>{' '}Profile</span></SidebarItem>,
        <SidebarItem><span onClick={() => {clearSessionCookie(); window.location.href='/login' }} className={s.link}><RiLogoutBoxLine/>{' '}Logout</span></SidebarItem>)
    };

      if(session && isMentor(session)) {
        items.splice(3, 0, <SidebarItem href='/jobs?pg=1'><span className={s.link}><RiBriefcase2Line/>{' '}Job Search</span></SidebarItem>)
    }

    if(session && session.userId && isPartner(session)) {
        items.push(<SidebarItem href='/dashboard/partner'><span className={s.link}><RiDashboardFill/>{' '}Dashboard</span></SidebarItem>,
        <SidebarItem href='/meetings/partner'><span className={s.link}><RiCalendar2Fill/> {' '}Meetings</span></SidebarItem>,
        <SidebarItem href='/schools/partner'><span className={s.link}><FaGraduationCap/> {' '}Schools</span></SidebarItem>,
        <SidebarItem href='/students/partner'><span className={s.link}><RiUser3Fill/> {' '}Students</span></SidebarItem>,
        <SidebarItem href='/settings'><span className={s.link}><RiSettings3Line/>{' '}Settings</span></SidebarItem>,
        <SidebarItem><span onClick={() => {clearSessionCookie(); window.location.href='/login' }} className={s.link}><RiLogoutBoxLine/>{' '}Logout</span></SidebarItem>)
    }

    if(session && session.id && isEmployer(session)) {
        items.push(<SidebarItem href='/dashboard/employer'><span className={s.link}><RiDashboardFill/>{' '}Dashboard</span></SidebarItem>,
        <SidebarItem href='/applications/employer'><span className={s.link}><RiNewspaperLine/>{' '}Applications</span></SidebarItem>,
        <SidebarItem href='/interviews/employer'><span className={s.link}><RiCalendar2Fill/> {' '}Interviews</span></SidebarItem>,
        <SidebarItem href='/recruit'><span className={s.link}><RiBriefcase2Line/>{' '}Recruit</span></SidebarItem>,
        <SidebarItem href={`/company/${session.companyId}`}><span className={s.link}><FiUsers/>{' '}Company Profile</span></SidebarItem>,
        <SidebarItem href='/payment/employer'><span className={s.link}><RiMoneyDollarCircleFill/>{' '}Payment</span></SidebarItem>,
        <SidebarItem href='/inbox'><span className={s.link}><RiMailFill/>{' '}Inbox<UnreadMessageIndicator/></span></SidebarItem>,
        <SidebarItem href='/settings'><span className={s.link}><RiSettings3Line/>{' '}Settings</span></SidebarItem>,
        <SidebarItem><span onClick={() => {clearSessionCookie(); window.location.href='/login' }} className={s.link}><RiLogoutBoxLine/>{' '}Logout</span></SidebarItem>)
    }

  return <div>
    <Sidebar background='#108E73' toggleIconColor='white' width={260} content={items}>
        <Header title={title}/>
        <div className={s.content}>
            {props.children}
        </div>
      </Sidebar>
</div>
}

export default (SideBarContainer);
