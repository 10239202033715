import React, {useState, useEffect, useContext} from 'react'
import s from '../styles/University.module.scss'
import {PieChart, Pie, Cell, Tooltip} from 'recharts';
import {getGenderChartData, getRaceChartData, commafy, toPercent} from '../utils'
import {FaFemale, FaMale} from 'react-icons/fa'
import {HiUsers} from  'react-icons/hi'
import Table from 'react-bootstrap/Table'
import { MentorCard } from 'Components/Tile/Card';
import {SessionContext, isLoggedIn} from 'Utils/sessions'
import { Root } from 'Root'

const About = ({data}) => {
  const {session, refreshSession} =  useContext(SessionContext)
  const [students, setStudents] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const params = {
      ipedsid: data.ipedsid,
      userId: session.id,
      limit: 40
    }

    Root.collegeApi.getMatches(params).then(rsp => {
      setStudents(rsp.data)
    }).catch(() => {
      setStudents([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    )
  }, [session])

  return <div className={s.about}>
        <div className={s.row}>
            <h3 className='heading'><strong>Student Body</strong></h3>
            <StudentBodySection data={data}/>
        </div>

        <div className={s.row}>
             <h3 className='heading'><strong>NXSTEP Student Network</strong></h3>
            <div className='horScroll'>
                {students && students.map((s)=> {return <MentorCard mentor={s.first} className={`me-4 my-4 Discover_blogTile__HYLJA`} onClick={()=> window.location.href="/profile/student/" + s.first.userName}/>})}
                {!error && isLoggedIn(session) && students && students.length == 0 && <p className='text-muted fs-13'>No students found</p>}
                {!isLoggedIn(session) && <p className='mb-0 pb-0 text-muted fs-13'>Sign up <a className='blue' href="/register">here</a> to browse students</p>}
                {error && <p className='text-muted fs-13'>{error}</p>}
                {!students && <Spinner style={{color:"rgb(16, 142, 115)"}} animation="border" role="status"/>}
            </div>
        </div>

        <div className={s.row}>
            <h3 className='heading'><strong>General</strong></h3>
            <GeneralSection data={data} />
        </div>
        <div className={s.row}>
            <h3 className='heading'><strong>Tuition and Fees</strong></h3>
            <FinancialSection data={data} />
        </div>
</div>
}

export default (About);

const GeneralSection = ({data}) => {
    return <div className={s.tile}>
         <Table className={s.table} striped responsive>
    <tbody>
      <tr>
        <td>Student to Faculty Ratio</td>
        <td>{data.studentToFacultyRatio ? `${data.studentToFacultyRatio}:1 ` : '-'}</td>
      </tr>
      <tr>
        <td>Retention Rate</td>
        <td>{(data.retentionRate > 0 && data.retentionRate) ? toPercent(data.retentionRate) : '-' }%</td>
      </tr>
      <tr>
          <td>Acceptance Rate</td>
          <td>{(data.acceptanceRate > 0 && data.acceptanceRate) ? toPercent(data.acceptanceRate) : '-' }%</td>
      </tr>
      <tr>
        <td>Student to Faculty Ratio</td>
        <td>{data.studentToFacultyRatio || '-'}</td>
      </tr>
      <tr>
        <td>Degrees</td>
        <td>{data.associates === true && 'AA'} {data.bachelors === true && 'BA'} {data.masters === true && 'MS'} {data.doctorates === true && 'PhD'} </td>
      </tr>
    </tbody>
  </Table>
  </div>
}

export const FinancialSection = ({data}) => {
    return <div className={s.tile}>
    <Table size="sm" className={s.table} striped responsive>
  <tbody>
    <tr>
      <td>Average Annual Cost</td>
      <td>${commafy(data.averageAnnualCost) || ' -'}</td>
    </tr>
    <tr>
      <td>In State Tuition</td>
      <td>${commafy(data.inStateTuition) || ' -'}</td>
    </tr>
    <tr>
      <td>Out of State Tuition</td>
      <td>${commafy(data.outOfStateTuition) || ' -'}</td>
    </tr>
  </tbody>
  </Table>
  </div>
  }

const StudentBodySection = ({data}) => {
    return <div className={`${s.studentBody} ${s.tile}`}>
                <div className='d-flex align-items-center justify-content-center'>
                    <span>
                        <p className='text-center'><span className={`${s.stat} ${s.purple}`}><HiUsers size={30}/></span> </p>
                        <p className='text-center mb-2'>Total Enrollment</p>
                        <p style={{fontSize:'24px'}} className='text-center'><strong>{data.totalEnrollment ? commafy(data.totalEnrollment) : '-'}</strong></p>
                    </span>
                </div>
              <div>
                <div className='d-flex align-items-center justify-content-center'>
                <PieChartCustom data={getGenderChartData(data)} label={''}/>
                <span>
                    <p className='text-center'>Gender</p>
                    <p className={`mb-0 ${s.purple}`}><FaMale/> <strong>{data.men ? toPercent(data.men) : '-'}%</strong></p>
                    <p className={` ${s.green}`}><FaFemale/> <strong>{data.women ? toPercent(data.women) : '-'}%</strong></p>
                </span>
                </div>
              </div>
              <div>
                <div className='d-flex align-items-center justify-content-center'>

                {(!data.white && !data.black && !data.aian && !data.asian && !data.hispanic)
                  ? <p className={s.stat}>-</p>
                  : <>
                  <PieChartCustom data={getRaceChartData(data)} label={'% of Population'}/>
                </>}
                <span>
                    <p className='mb-0 fs-13'><strong className={s.purple}>{data.white ? toPercent(data.white) : '-'}%</strong> white</p>
                    <p className='mb-0 fs-13'><strong className={s.green}>{data.black ? toPercent(data.black) : '-'}%</strong> black</p>
                    <p className='mb-0 fs-13'><strong className={s.purple}>{data.asian ? toPercent(data.asian) : '-'}%</strong> asian</p>
                    <p className='mb-0 fs-13'><strong className={s.green}>{data.multiracial ? toPercent(data.multiracial) : '-'}%</strong> multicultural</p>
                    <p className='mb-0 fs-13'><strong className={s.green}>{data.aian ? toPercent(data.aian) : '-'}%</strong> indigenous</p>
                    <p className='mb-0 fs-13'><strong className={s.green}>{data.hispanic ? toPercent(data.hispanic) : '-'}%</strong> hispanic</p>
                    <p className='mb-0 fs-13'><strong className={s.green}>{data.pacific ? toPercent(data.pacific) : '-'}%</strong> pacific islander</p>
                    <p className='mb-0 fs-13'><strong className={s.green}>{data.unknownRace ? toPercent(data.unknownRace) : '-'}%</strong> unknown</p>
                    <p className='mb-0 fs-13'><strong className={s.green}>{data.nonResidentAlien ? toPercent(data.nonResidentAlien) : '-'}%</strong> non-resident</p>
                </span>
                </div>
              </div>
            </div>
      }

export const PieChartCustom = (props) => {
    const COLORS =["rgb(118, 120, 237)", "#46BFBD", "#FDB45C", "#949FB1", "#4D5360", "#fc03df", "#525ec4", "#f7ea2f", "#F7464A"];

    return <>
    <p>{props.title}</p>
    <PieChart width={150} height={150} className="">
            <Pie
            data={props.data}
            dataKey={"value"}
            outerRadius={50}
            fill="#8884d8"
            paddingAngle={5}
            label>
                {
                props.data.map((entry, idx) => <Cell key={idx} fill={COLORS[idx % COLORS.length]}/>)
            }
            </Pie>
            <Tooltip verticalAlign="top" height={36}/>
    </PieChart>
</>
}
