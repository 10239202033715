import React, { useState, useEffect } from 'react'
import styles from '../styles/University.module.scss'
import { Root } from 'Root'
import { FaCheckCircle } from 'react-icons/fa'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { MentorCard } from 'Components/Tile/Card';
import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner'
import { standardToMiltary, getPrettyDate, boolIdxToTime, getTimezone } from 'Utils/time'
import placeholder from 'Static/students_1.jpg'
import avi from 'Static/default-avi.png'
import { isMentee } from 'Utils/sessions';

export const ScheduleMeeting = ({session, student, isInterview, applicationId, companyId, jobPostingId}) => {
  const [duration, setDuration] = useState(30)
  const [dates, setDates] = useState({})
  const [callType, setCallType] = useState(null)
  const [meetingDate, setMeetingDate] = useState(null)
  const [meetingTime, setMeetingTime] = useState(null)
  const [description, setDescription] = useState('')
  const [formErrors, setFormErrors] = useState('')
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [availError, setAvailError] = useState(null)
  const [times, setTimes]  = useState(null)
  const handleCallTypeChange = (value) => {setCallType(value)}
  const handleDescriptionChange = (event) => { setDescription(event.target.value)}
  
    useEffect(() => {
      // Get dates for the next month
      setDates({})
      var today = new Date()
      for (var i = 0; i <= 30; i++) {
        const date = new Date(today.getFullYear(), today.getMonth(), today.getDate() + i)
        setDates(prevState => ({ ...prevState, [date]: [] }))
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

  useEffect(()=>{
    if(meetingDate) {
      setTimes(null);
      const dateObj = new Date(meetingDate)
      // account for month offset
      var dateString = dateObj.getFullYear() + '-' + (dateObj.getMonth() + 1) + '-' + dateObj.getDate()
      Root.collegeApi.checkMeetingAvailability(student.userId || student.id, dateString, getTimezone(), duration)
      .then(rsp => {setTimes(rsp.data);})
      .catch(()=> { setTimes([]); setAvailError("Error getting availability"); })
    }
  },[meetingDate])

  function getSelectTimeOptions(boolArr) {
    let options = []
    boolArr.forEach((el,idx) => {
        if(el) {
            options.push(boolIdxToTime(idx))            
        }
    })
    return options
  }

  function getDisplayDate(date){
    let dateString = getPrettyDate(date)
    let parts = dateString.split(' ')
    let res = [parts[1], parts[2].replace(/,/g, '')]
    return res
  }

  function handleRequestMeeting () {

    setFormErrors(null)
    if (!callType) {
      setFormErrors('Please select preferred call type')
      return
    }
    if (!meetingTime || !meetingDate) {
      setFormErrors('Please select a meeting date and time')
      return
    }

    const dateObj = new Date(meetingDate)
    // account for month offset
    var dateString = dateObj.getFullYear() + '-' + (dateObj.getMonth() + 1) + '-' + dateObj.getDate()

    var dateTime = new Date(`${dateObj.getFullYear() + '-' + (dateObj.getMonth() + 1) + '-' + dateObj.getDate()} ${meetingTime}`)
    if (dateTime < new Date()) {
      setFormErrors('Please select a valid future start time')
      return
    }

    if (isInterview) {
      const request = {
        companyId: companyId, 
        jobPostingId: jobPostingId,
        applicationId: applicationId,
        employeeId: session.id || session.userId,
        date: dateString,
        time: standardToMiltary(meetingTime),
        notes: description,
        primaryCommunication: callType,
        timezone: getTimezone(),
      }

      Root.careerApi.requestInterview(request).then(() => {
          setSubmitSuccess(true)
          setDescription('')
          setMeetingTime(null)
          setMeetingDate(null)
          setCallType(null)
      }).catch(() => {
        setFormErrors('Something went wrong, please try again later')
      })

    } else {
      const request = {
        prospectiveStudent: session.id,
        currentStudent: student.userId,
        date: dateString,
        time: standardToMiltary(meetingTime),
        description: description,
        primaryCommunication: callType,
        duration: parseInt(duration),
        timezone: getTimezone()
      }

      Root.collegeApi.requestMeeting(request).then(rsp => {
          setSubmitSuccess(true)
          setDescription('')
          setMeetingTime(null)
          setMeetingDate(null)
          setCallType(null)
      }).catch(() => {
        setFormErrors('Something went wrong, please try again later')
      })
    }
  }

  return <>
      <Modal.Header  closeButton>
        <div >
        <Modal.Title><strong>Schedule</strong></Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body className={styles.modalBody}>
        <img className='mb-3' style={{width:'100%', height:'200px', objectFit:'cover', borderRadius:'10px'}} src={placeholder} />

        <div className='d-flex justify-content-between'>
          <span>
            <span className='d-block mb-2'>Student</span>
            <img src={student.photo || avi} style={{height:'50px', width:'50px', borderRadius:'50%', objectFit:'cover'}}/>
            <span className='ms-2'>{student.firstName}</span>
          </span>
         {isMentee(session) && <span>
            <span className='d-block mb-2'>Price</span>
            <span className='green'>
              <strong>${duration === 30 ? process.env.REACT_APP_MEETING_PRICE_30 : process.env.REACT_APP_MEETING_PRICE_60}
              </strong></span>
          </span>} 
        </div> 
        <Button className='btnLink fs-14 ps-0 sm' onClick={()=> window.location.href=`/profile/student/${student.userId || student.id}`}>View Profile</Button>

        <p className='mt-3'>Duration</p>
        <Button className={`${duration === 30 ? styles.tileActive: styles.tileInactive} btnOutline me-2`} onClick={()=>setDuration(30)}>30 Min</Button>
        <Button className={`${duration === 60 ? styles.tileActive: styles.tileInactive} btnOutline`}
        onClick={()=>setDuration(60)}>1 Hour</Button>

        <p className='mt-4'>Availability</p>
            <div className='horScroll'>
                {Object.keys(dates).sort((a, b) => new Date(b) - new Date(a)).reverse().map((date, idx) =>
                  <Button className={`${meetingDate === date ? styles.tileActive: styles.tileInactive} btnOutline me-3`}
                  onClick={(e) => {setMeetingDate(date); setMeetingTime(null)}}>
                    {getDisplayDate(date)[1]}
                    <br/>
                    {getDisplayDate(date)[0]}
                  </Button>
                )}
            </div>
          <p className='mt-4'>Select time</p>
          <div className='horScroll'>
            {meetingDate && <> { times ?
            getSelectTimeOptions(times).map(time => 
              <Button className={`${meetingTime === time ? styles.tileActive: styles.tileInactive} btnOutline me-2`}
              onClick={(e) => {setMeetingTime(time)}}>
                {time}
              </Button>)
              :  <Spinner style={{color:"rgb(16, 142, 115)"}} animation="border" role="status"/>} 
              </> }
          </div>
          <p className='form-error'>{availError}</p>

          <Form.Label className='mt-4'>Call Type</Form.Label>
          <div className="mb-3">
            <Form.Check inline label="Video Chat" type={'checkbox'} onChange={() => handleCallTypeChange('VIDEO')}
              checked={callType === 'VIDEO'} />
            <Form.Check inline label="Audio Chat" type={'checkbox'} onChange={() => handleCallTypeChange('AUDIO')}
              checked={callType === 'AUDIO'}/>
          </div>

          <Form.Group className='mt-4 mb-4'>
            <Form.Label>Message (Optional)</Form.Label>
            <Form.Control as="textarea" rows="3" maxLength="50" value={description} onChange={handleDescriptionChange}/>
          </Form.Group>

          {!submitSuccess ? <Button onClick={handleRequestMeeting} className={'btnFill lg'}>Send Request</Button> :
          <p><FaCheckCircle size="30" className={'green'}/> Your request has been sent</p>}
          <div className={'form-error'}>{formErrors}</div>

      </Modal.Body>
      </>
}

const BookMeeting = ({session, ipedsid}) => {
  const [matches, setMatches] = useState(null)
  const [student, setStudent] = useState(null)
  const [show, setShow] = useState(false)
  const handleHide = () => {setShow(false); setStudent(null); }

  // On page load get prospect's matches
  useEffect(() => {
    const params = {
      ipedsid: ipedsid,
      userId: session.id,
      limit: 20
    }

    Root.collegeApi.getMatches(params).then(rsp => {
      setMatches(rsp.data)
    }).catch(() => {
      setMatches([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    )
  }, [ipedsid, session])

  return <div>
    <Button onClick={()=> setShow(true)} className='btnFill wide mt-4'>Book Meeting With Student</Button>
    <Modal onHide={handleHide} show={show} className={styles.bookingModal}>
        { !student && <>
          <Modal.Header closeButton>
              <Modal.Title><strong>Book Meeting</strong></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Recommended available student matches</p>
            <div>
              {matches ? <>
                {session.profile && matches.length === 0 && <p className='text-center text-muted fs-13'>No matches found. </p>}
                {!session.profile && <p className='text-center text-muted'>You must complete your personality profile in order to get matched!</p>}
                <div className={'responsiveGridSm'}>
                  {matches.map((match, idx) => <>
                  <MentorCard mentor={match.first} className={styles.mentorCard} onClick={() => { setStudent(match.first) }}/>
                  </>)}
                </div>
              </>
                : <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                  <Spinner style={{color:"rgb(16, 142, 115)"}} animation="border" role="status"/>
                </div>
              }
            </div>
          </Modal.Body>
        </>}

        {/* VIEW AVAILABILITY */}
        { student && <ScheduleMeeting student={student} session={session} ipedsid={ipedsid}/> }
          <Modal.Footer>
            <Button className={'btnLink'} onClick={()=> setStudent(null)}>Back</Button>
          </Modal.Footer>
    </Modal>
  </div>
}

export default (BookMeeting)
