import React, { useState, useEffect } from 'react'
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import { TextInput } from '../../../Components/Input/Input';
import {Root} from 'Root'
import styles from '../styles/University.module.scss'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import {IoIosSend} from "react-icons/io";
import s from "../../Dashboard/styles/Inbox.module.scss";
import Spinner from "react-bootstrap/Spinner";
import {sanitizeHtml} from "bootstrap/js/src/util/sanitizer";

const ChatNXSTEPAI = ({session, sessionStartTime, ipedsid, collegeName}) => {
    const [aiChatThread, setAIChatThread] = useState({messages: []})
    const [sending, setSending] = useState(false)
    const [message, setMessage] = useState(null)
    const [messageError, setMessageError] = useState(null)
    const [show, setShow] = useState(false)
    const handleHide = () => {setShow(false); }

    const keyDown = (event) => {
        if (event.key === "Enter" && !!message && message.length > 0) {
            askAi();
        }
    }

    const scrollToBottom = () => {
        const messagesContainer = document.getElementById('messagesContainerRef');

        if (messagesContainer) {
            messagesContainer.lastElementChild.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    };

    useEffect(() => {
        document.addEventListener('new-notification-received', (event) => {
            getAiChatThread();
        });
    }, []);


    useEffect(() => {
        const sock = new SockJS(`${Root.baseURL()}/api/v2/notificationsocket`);
        const stompClient = Stomp.over(sock);
        stompClient.debug = function (){}; //do nothing
        const userId = session.id || session.userId
        stompClient.connect({}, (frame) => {
            stompClient.subscribe(`/messaging/${userId}/ai-notifications`, (response) => {
                getAiChatThread();
                markAiChatAsRead();
            });
        });
    }, [aiChatThread]);


    useEffect(()=> {
        getAiChatThread();
    },[show])

    function getAiChatThread(){
        Root.miscApi.getAiChatThread(session.id || session.userId)
            .then((res) => {
                setAIChatThread(res.data);
                if (show) {
                    scrollToBottom();
                }
            })
            .catch((err)=> {});
    }

    function markAiChatAsRead() {
        Root.miscApi.readAIChat(session.id || session.userId)
            .then(() => {
                // Create a new CustomEvent. The first argument is the event name.
                const myEvent = new CustomEvent('thread-marked-read');
                // Dispatch the event on the document:
                document.dispatchEvent(myEvent);
            }).catch((err)=> {
            console.log(err)
        })
    }


    function askAi() {
        setMessageError(null)
        if(!message || message.length === 0) {
            return
        }

        setSending(true)
        Root.miscApi.askAI(session.id || session.userId, message, collegeName, ipedsid).then(()=> {
            setMessage(null)
            setMessageError(null)
            getAiChatThread()
            setSending(false)
        }).catch(()=> {
            getAiChatThread()
            setSending(false);
            setMessageError('Error asking AI, please try again');
        });
    }

    function isToday(ts) {
        var today = new Date().setHours(0, 0, 0, 0);
        var thatDay = new Date(ts).setHours(0, 0, 0, 0);
        return today === thatDay
    }

    const sanitize = (dirty) => ({
        __html: sanitizeHtml(dirty, {
            allowedTags: false,
            allowedAttributes: false
        })
    });

    const SanitizeHTML = ({ html }) => (
        <div dangerouslySetInnerHTML={sanitize(html)} />
    );

    return <div>
        <Button onClick={()=> { setShow(true);}} className='btnOutline wide mt-4'>Chat With AI</Button>
        <Modal onHide={handleHide} show={show} className={styles.bookingModal}>
            <Modal.Header closeButton>
                <Modal.Title><strong>Start a new topic with AI</strong></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Start your conversation here by asking any question you'd like about <i>{collegeName}</i>.
                    You can resume this conversation at any time from your <a href={'/inbox'}>inbox</a>.</p>
                <div className={styles.aiChatHistoryContainer}>
                    <div className={styles.aiChatHistory} id="messagesContainerRef">
                        {aiChatThread.messages.sort((a, b) => a.timestamp - b.timestamp
                        ).filter(m => m.timestamp >= sessionStartTime).map((m, idx) => {
                            return <>
                                <div className={m.isUser ? s.msgSent : s.msgRec}>
                                <span>
                                    <p className='mb-0'>
                                        <SanitizeHTML html={m.message} />
                                    </p>
                                    {<span
                                        className='fs-10 d-block mb-1 text-muted'>{isToday(m.timestamp) && new Date(m.timestamp).toLocaleTimeString([], {
                                        hour: '2-digit',
                                        minute: '2-digit'
                                    })}</span>}
                                </span>
                                </div>
                            </>
                        })
                        }
                        <div id="messagesEndRef"/>
                        {/* Empty div at the bottom */}
                    </div>
                </div>
                <div onKeyDown={keyDown} className={styles.input}>
                    <TextInput containerClassName='mb-1' errors={{'message': messageError}} name="message"
                               value={message || ''} onChange={(e) => setMessage(e.target.value)} placeholder={'...'}/>
                    {sending ? <><Spinner style={{color: "rgb(16, 142, 115)"}} animation="border" role="status"/></> :
                        <><Button className='btnLink' onClick={askAi}><IoIosSend size={25}/></Button></>}
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button className={'btnLink'} onClick={() => setShow((false))}>Maybe later</Button>
            </Modal.Footer>
        </Modal>
    </div>
}

export default (ChatNXSTEPAI)
