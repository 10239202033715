import axios from 'axios'
import * as vars from './vars';

export function indicateHasReadInfo (userId){
    const params = new URLSearchParams({userId: userId})
    return axios({
        method: 'POST',
        url: vars.baseURL + '/api/vs/users/registration/read_info?' + params,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data : {
        }
    })
}

    // data fields
    // allAlerts: Boolean (Optional)
    // generalUpdates: Boolean (Optional)
    // allTextAlerts: Boolean (Optional)
    // meetingProposalTextAlerts: Boolean (Optional)
    // meetingScheduledTextAlerts: Boolean (Optional)
    // meetingCancellationTextAlerts: Boolean (Optional)
    // meetingReminderTextAlerts: Boolean (Optional)
    // actionRequiredTextAlerts: Boolean (Optional)
    // paymentTextAlerts: Boolean (opional)
    // allEmailAlerts: Boolean (Optional)
    // meetingProposalEmailAlerts: Boolean (Optional)
    // meetingScheduledEmailAlerts: Boolean (Optional)
    // meetingCancellationEmailAlerts: Boolean (Optional)
    // meetingReminderEmailAlerts: Boolean (Optional)
    // newsletterEmailAlerts: Boolean (Optional)
    // actionRequiredEmailAlerts: Boolean (Optional)
    // paymentEmailAlerts: Boolean (optional)
 export function updateAlertSettings (userId, data) {
    const params = new URLSearchParams({userId: userId})
    return axios({
        method: 'PATCH',
        url: vars.baseURL + '/api/v2/alerts/user/update?' + params,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data : data
    })
}

export function getAlertSettings (userId) {
    const params = new URLSearchParams({userId: userId})
    return axios({
        method: 'GET',
        url: vars.baseURL + '/api/v2/alerts/user/get?' + params,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: {}
    })
}

export function getAllNotifications (userId) {
    const params = new URLSearchParams({state: 'unread'})
    return axios({
        method: 'GET',
        url: vars.baseURL + '/api/v2/messaging/notifications/recipients/' + userId + "?" + params,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: {}
    })
}

export function markNotificationRead (userId, notificationId){
  const params = new URLSearchParams({operation: 'read'})
    return axios({
        method: 'PATCH',
        url: vars.baseURL + '/api/v2/messaging/notifications/notification/' + notificationId + "?" + params,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data : { operation: 'read' }
    })
}

export function markThreadNotificationRead (userId, threadId){
  const params = new URLSearchParams({operation: 'read', userId: userId, threadId: threadId})
    return axios({
        method: 'PATCH',
        url: vars.baseURL + '/api/v2/messaging/notifications' + "?" + params,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data : { operation: 'read', userId: userId, threadId: threadId }
    })
}

export function registerDeviceToken (userId, deviceId, token, platform) {
  const environment = ['localhost', '127.0.0.1'].includes(window.location.hostname) ? "DEVELOPMENT" : "PRODUCTION";
    return axios({
        method: 'PUT',
        url: vars.baseURL + '/api/v2/messaging/notifications/devices',
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data : {
          userId: userId,
          platform: platform,
          environment: environment,
          token: token,
          deviceId: deviceId
        }
    })
}

export function unRegisterDeviceToken (userId, endpoint, platform) {
    return axios({
        method: 'DELETE',
        url: vars.baseURL + '/api/v2/messaging/notifications/devices',
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data : {
          userId: userId,
          platform: platform,
          token: endpoint
        }
    })
}
