import React from 'react';
import { NavBar } from 'Components/Nav/NavBar';
import s from './styles/About.module.scss';
import Footer from './Footer';

const About = () => (
  <>
    <div className={s.about}>
      <div className={s.sectionOne}>
      <NavBar />
        <div className={`${s.container} pt-5`}>
          <h1><strong>About Us</strong></h1>
          <h3 className='fs-16'>Innovating the college search process</h3>
        </div>
      </div>
      <div className={`${s.sectionTwo} ${s.container} ${s.preFooter}`}>
        <img src={'https://cdn.cassy.dev/about.svg'}/>
        <h2>Our Motivation</h2>
        <div className={s.grid}>
          <p>Imagine spending thousands of dollars and countless hours touring schools across the country. In the moment, you're excited to see the different campuses and learn about the history of all these different schools that you’ve never seen before. After touring over 5 schools, you finally come across one which has a beautiful campus, a prestigious name, and a great history. You apply, get accepted and you feel that everything is going your way. Two years later you are entering the transfer portal because you realize that the school is nothing like what you thought it would be and that the knowledge you had of the school was very surface level. This is the unfortunate reality for many students.</p>
          <p>Over ⅓ of college students transfer at least once during their college career. Not only is transferring colleges expensive but it is also inconvenient. In some cases, it can even lead to depression and different forms of anxiety. Our research found that these transfers are due to a disconnect between one’s expectations of a college and the reality they are met with when they finally get there. This is a problem that stems not only from misinformation and miscommunication in the college information space but also from a lack of personalization. This is why we created NXSTEP.</p>
        </div>
        <h2 className={s.banner}>ENGAGE IN A MEANINGFUL, TRANSPARENT, AND UNBIASED DISCUSSION ABOUT THE SCHOOL OF YOUR DREAMS</h2>
        <h2>Our Platform</h2>
        <div className={s.grid}>
          <p>NXSTEP is a one of a kind platform that allows prospective college students to have a 1-on-1 live virtual discussion with a real college student- matched specifically to them by major, personality, etc! Think of it as talking to your future self to gauge whether a school is right for you. During these conversations, you’re encouraged to ask any question that you may have without holding back. Don’t know what questions to ask? We got you! In the menu we provide a questions guide that you can always refer to in order to jumpstart conversation and get the most out of your meetings.</p>
          <p>
            Unlike traditional college tours and other online resources, NXSTEP provides you with an open, honest and comfortable environment to ask questions that you find important. This means that none of the college students are paid to make the school look good but rather to give you their raw and honest feedback.
            Here at NXSTEP, we are committed to creating the ultimate tool to help high school students find the college that’s perfect for them! This means always innovating, improving, and discussing with our users in order to make sure that we are not only the best in the business but the best for them.
          </p>
        </div>
        <h2 className={s.banner}>A PERSONALIZED, AFFORDABLE, CONVENIENT AND EFFECTIVE COLLEGE SEARCH EXPERIENCE</h2>
      </div>
    </div>
    <Footer />
  </>
);

export default (About);
