import './Sass/App.scss';
import React, {useState, useContext, lazy, Suspense} from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import EmployerLanding from 'Pages/Landing/EmployerLanding'
import About from 'Pages/Landing/About'
import Blog from 'Pages/Landing/Blog'
import {NYCCollegeLife, CollegeEssentials, Transfer, GradSchool, BeatTheOdds} from 'Pages/Landing/BlogPost'
import Login from 'Pages/Landing/Login'
import Register from 'Pages/Landing/Register'
import EmployeeRegister from 'Pages/Landing/EmployeeRegister'
import CompanyRegister from 'Pages/Landing/CompanyRegister'
import Privacy from 'Pages/Landing/Privacy'
import TermsOfService from 'Pages/Landing/TermsOfService'
import IndependentContractorServiceContract from 'Pages/Landing/IndependentContractorServiceContract'
import Contact from 'Pages/Landing/Contact'
import Recover from 'Pages/Landing/Recover'
import TopTalent from 'Pages/Landing/TopTalent'
import StudentLanding from 'Pages/Landing/CollegeLanding'
import Applicants from 'Pages/Landing/Applicant'
import Discover  from './Pages/Discover/Discover'
import University from './Pages/Discover/University'
import StudentProfile from './Pages/Dashboard/Profile/StudentProfile';
import Invites  from './Pages/Dashboard/Invites'
import Jobs from './Pages/Company/Jobs'
import Job from './Pages/Company/Job'
import Company from './Pages/Company/Company'
import Ethically from './Pages/Landing/Ethically'

import { SessionContext, getSessionCookie, updateSessionCookie, isMentor, isMentee, isPartner, isStudent, setSessionCookie, isEmployer} from './Utils/sessions';
import { createBrowserHistory } from "history";
import useDeepCompareEffect from 'use-deep-compare-effect'
import { Root } from 'Root';
import Spinner from 'react-bootstrap/Spinner'
import Search from './Pages/Discover/Search';
import BrowseStudents from './Pages/Company/Students';

const history = createBrowserHistory();

const renderLoader = () => <div style={{position:'absolute', top:0, bottom:0, left:0, right: 0, backgroundColor:'dodgerblue', display:'flex', alignItems:'center', justifyContent:'center'}}>
    <Spinner style={{color:"white"}} animation="border" role="status"></Spinner>
</div>;

const PrivateRoute = ({ component: ChildComponent, ...props }) => {
  const {session, refreshSession} = useContext(SessionContext);
  return <Route {...props} render={() => {
    if(!session.id && !session.userId) {
      return <Redirect to='/login'/>
    } else {
      return <Suspense fallback={renderLoader()}>
        <ChildComponent {...props} />
      </Suspense>
    }
  }} />
}

const PartnerRoute = ({ component: ChildComponent, ...props }) => {
  const {session, refreshSession} = useContext(SessionContext);
  return <Route {...props} render={() => {
    if(!session.id && !session.userId) {
      return <Redirect to='/login'/>
    } else if (isPartner(session)){
      return <Suspense fallback={renderLoader()}>
        <ChildComponent {...props} />
      </Suspense>
    } else {
      return <Redirect to='/dashboard/student'/>
    }
  }} />
}

const CollegeStudentRoute = ({ component: ChildComponent, ...props }) => {
  const {session, refreshSession} = useContext(SessionContext);
  return <Route {...props} render={() => {
    if(!session.id && !session.userId) {
      return <Redirect to='/login'/>
    } else if (isMentor(session)){
      return <Suspense fallback={renderLoader()}>
        <ChildComponent {...props} />
      </Suspense>
    } else {
      return <Redirect to='/dashboard/student'/>
    }
  }} />
}

const ApplicantRoute = ({ component: ChildComponent, ...props }) => {
  const {session, refreshSession} = useContext(SessionContext);
  return <Route {...props} render={() => {
    if(!session.id && !session.userId) {
      return <Redirect to='/login'/>
    } else if (isMentee(session)){
      return <Suspense fallback={renderLoader()}>
        <ChildComponent {...props} />
      </Suspense>
    } else {
      return <Redirect to='/'/>
    }
  }} />
}

const StudentRoute = ({ component: ChildComponent, ...props }) => {
  const {session, refreshSession} = useContext(SessionContext);
  return <Route {...props} render={() => {
    if(!session.id && !session.userId) {
      return <Redirect to='/login'/>
    } else if (isStudent(session)){
      return <Suspense fallback={renderLoader()}>
        <ChildComponent {...props} />
      </Suspense>
    } else {
      return <Redirect to='/dashboard/student'/>
    }
  }} />
}

const EmployerRoute = ({ component: ChildComponent, ...props }) => {
  const {session, refreshSession} = useContext(SessionContext);
  return <Route {...props} render={() => {
    if(!session.id && !session.userId) {
      return <Redirect to='/login'/>
    } else if (isEmployer(session)){
      return <Suspense fallback={renderLoader()}>
        <ChildComponent {...props} />
      </Suspense>
    } else {
      return <Redirect to='/'/>
    }
  }} />
}

function App() {
  const [session, setSession] = useState(getSessionCookie());
  const refreshSession = () => {
    if (session === undefined || session === {}) {
      return;
    } else {

      if(isEmployer(session)) {
        Root.careerApi.getEmployee(session.id || session.userId).then(res => {
          updateSessionCookie(res.data)
        }).then(() => {
         window.location.reload(true);
         return false;
        }).catch((err) => {
          console.log(err.response)
          return;
        })
        return
       } else {
          Root.userApi.getUserBasic(session.id).then(res => {
            updateSessionCookie(res.data)
          }).then(() => {
          window.location.reload(true);
          return false;
          }).catch((err) => {
            console.log(err.response)
            return;
          })
        }
    }
  };

  const value = {session, refreshSession};
  useDeepCompareEffect(
    () => {
      setSession(getSessionCookie());
    },
    [session]
  );

// Lazy loading to improve performance
const Schedule = lazy(() => import('./Pages/Dashboard/Schedule'));
const Media = lazy(() => import('./Pages/Dashboard/Media'));
const Settings = lazy(() => import('./Pages/Settings/Settings'));
const Inbox = lazy(() => import('./Pages/Dashboard/Inbox'));
const Meeting = lazy(() => import('./Pages/Dashboard/Meeting'));
const Onboarding = lazy(() => import('./Pages/Discover/Onboarding'));
const CompanyDashboard = lazy(() => import('./Pages/Company/Dashboard'));
const Applications = lazy(() => import('./Pages/Company/Applications'));
const CompanyPayment = lazy(() => import('./Pages/Company/Payment'));
const Interviews = lazy(() => import('./Pages/Company/Interviews'));
const Interview = lazy(() => import('./Pages/Company/Interview'));
const Dashboard = lazy(() => import('./Pages/Dashboard/Dashboard'));
const PartnerDashboard = lazy(() => import('./Pages/Partner/Dashboard'));
const PartnerMeetings = lazy(() => import('./Pages/Partner/Meetings'));
const PartnerSchool = lazy(() => import('./Pages/Partner/School'));
const PartnerSchools = lazy(() => import('./Pages/Partner/Schools'));
const PartnerStudents = lazy(() => import('./Pages/Partner/Students'));
const StudentApplications = lazy(() => import('./Pages/Dashboard/Profile/Applications'));
const StudentConnections = lazy(() => import('./Pages/Dashboard/Profile/Connections'));
const EditStudentProfile = lazy(() => import('./Pages/Dashboard/Profile/EditStudentProfile'));
const ApplicantProfile = lazy(() => import('./Pages/Dashboard/Profile/ApplicantProfile'));
const Connections = lazy(() => import('./Pages/Dashboard/Profile/Connections'));
const Payment = lazy(() => import('./Pages/Dashboard/Payment'));

  return (
    <SessionContext.Provider value={value}>
      <Router history={history}>
        <Switch>
          <Route exact path="/" component={StudentLanding}/>
          <Route path="/about" component={About}/>
          <Route exact path="/login" component={Login}/>
          <Route exact path="/recover" component={Recover}/>
          <Route exact path="/register" component={Register}/>
          <Route exact path="/register/student" component={Register}/>
          <Route exact path="/register/company" component={CompanyRegister}/>
          <Route exact path="/privacy" component={Privacy}/>
          <Route exact path="/tos" component={TermsOfService}/>
          <Route exact path="/ethically" component={Ethically}/>
          <Route exact path="/independent-contractor-service-contract" component={IndependentContractorServiceContract}/>
          <Route exact path="/contact" component={Contact}/>
          <Route exact path="/blog" component={Blog}/>
          <Route exact path="/top-talent" component={TopTalent}/>
          <Route exact path="/for-employers" component={EmployerLanding}/>
          <Route exact path="/for-students" component={StudentLanding}/>
          <Route exact path="/for-applicants" component={Applicants}/>
          <Route exact path={'/blog/college-life-in-nyc'}  component={NYCCollegeLife}/>
          <Route exact path={'/blog/college-essentials'}  component={CollegeEssentials}/>
          <Route exact path={'/blog/grad-school'}  component={GradSchool}/>
          <Route exact path={'/blog/transfer'}  component={Transfer}/>
          <Route exact path={'/blog/beating-the-odds'}  component={BeatTheOdds}/>
          <EmployerRoute exact path="/dashboard/employer" component={CompanyDashboard}/>
          <EmployerRoute exact path="/applications/employer" component={Applications}/>
          <EmployerRoute exact path="/interviews/employer" component={Interviews}/>
          <EmployerRoute exact path="/payment/employer" component={CompanyPayment}/>
          <PrivateRoute exact path="/interview" component={Interview}/>
          <StudentRoute exact path="/applications/student" component={StudentApplications}/>
          <StudentRoute exact path="/connections/student" component={StudentConnections}/>
          {/* <PrivateRoute exact path="/application" component={Application}/> */}
          <PartnerRoute exact path="/dashboard/partner" component={PartnerDashboard}/>
          <PartnerRoute exact path="/students/partner" component={PartnerStudents}/>
          <PartnerRoute exact path="/meetings/partner" component={PartnerMeetings}/>
          <PartnerRoute exact path="/schools/partner/:ipedsid" component={PartnerSchool}/>
          <PartnerRoute exact path="/schools/partner" component={PartnerSchools}/>
          <StudentRoute exact path="/dashboard/student" component={Dashboard}/>
          <ApplicantRoute exact path="/profile/applicant/:userName" component={ApplicantProfile}/>
          <CollegeStudentRoute exact path="/profile/student/edit" component={EditStudentProfile}/>
          <Route exact path="/profile/student/:userName" component={StudentProfile}/>
          <PrivateRoute exact path="/meeting" component={Meeting}/>
          <PrivateRoute exact path="/inbox" component={Inbox}/>
          <Route exact path="/invites" component={Invites}/>
          <Route exact path="/jobs" component={Jobs}/>
          <Route exact path="/job/:id" component={Job}/>
          <EmployerRoute exact path="/recruit" component={BrowseStudents}/>
          <Route exact path="/network" component={BrowseStudents}/>
          <Route exact path="/company/:id" component={Company}/>
          <StudentRoute exact path="/payment" component={Payment}/>
          <StudentRoute exact path="/onboarding" component={Onboarding}/>
          <Route exact path="/discover" component={Discover}/>
          <PrivateRoute exact path="/schedule" component={Schedule}/>
          <PrivateRoute exact path="/media" component={Media}/>
          <PrivateRoute exact path="/settings" component={Settings}/>
          <Route exact path={'/university'} component={University} />
          <Route exact path="/connections" component={Connections}/>
          <Route exact path={'/search'}  component={Search}/>
        </Switch>
      </Router>
    </SessionContext.Provider>
  );
}

export default App;
