import React, {useEffect,useState, useContext} from 'react'
import Card from 'react-bootstrap/Card'
import {Tile} from 'Components/Tile/Tile'
import defaultAvi from 'Static/default-avi.png';
import s from './Tile.module.scss'
import {Root} from 'Root';
import {majors} from 'Utils/majors'
import verifiedLogo from 'Static/verified_badge.png'
import backgroundImg from 'Static/backgroundImg.png'
import ReactTooltip from "react-tooltip";
import { FaStar } from 'react-icons/fa';
import AccordionContext from 'react-bootstrap/AccordionContext'
import { useAccordionButton } from 'react-bootstrap/AccordionButton'
import {BsThreeDots} from 'react-icons/bs'
import {IoMdSchool} from 'react-icons/io'
import checkSquare from 'Static/check_square.png';
import closeSquare from 'Static/close_square.png';
import Button from 'react-bootstrap/Button'


export const CollegeCard = ({college, className, key}) => {
  const [data, setData] = useState({})
  const [image, setImage] = useState(null)

  function handleRedirect () {
      const params = new URLSearchParams({
        name: college.collegeName,
        id: college.ipedsid
      })
      window.location.href = '/university?' + params
  }

  useEffect(()=>{
      Root.collegeApi.getCollegeImages(college.ipedsid).then((res) => {
        setImage(res.data[0])
      })
      },[college.ipedsid])

    useEffect(()=>{
      Root.collegeApi.getCollege(college.ipedsid).then(res => {
        if (res.data.length > 0) {
         setData(res.data[0])
        }
      })
    },[college.ipedsid])

    return <Card onClick={handleRedirect} className={`medCard mx-2 ${s.collegeCard} ${className}`} key={key} >
    <Card.Img style={{ height: '100px', objectFit: 'cover' }} variant="top" src={image || backgroundImg} />
    <Card.Body>
      <Card.Title className='mb-0 fs-16 trunc'>{college.collegeName || data.collegeName || '-'}</Card.Title>
      {data.mentorCount ?
        <Card.Text className='text-muted fs-13'>{data.mentorCount || '-'} mentors</Card.Text> :
        <Card.Text className='text-muted fs-13'>{data.totalEnrollment || '-'} students</Card.Text>
      }
    </Card.Body>
  </Card>
};

export const MentorCard = ({mentor, connection=null, ...props}) => {

  function ContextAwareToggle ({ eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext)

    const decoratedOnClick = useAccordionButton(
      eventKey,
      () => callback && callback(eventKey)
    )

    const isCurrentEventKey = currentEventKey === eventKey
    return (
      <div onClick={() => { decoratedOnClick() }}>
        <BsThreeDots style={{cursor:'pointer'}}/>
      </div>
    )
  }

  return <Card onClick={props.onClick} className={`smCard ${s.card} ${props.className}`}>
  <Card.Body>

    <img className={s.avi} style={{cursor:'pointer'}} src={mentor.photo || defaultAvi} alt='avi'/>

    <Card.Text style={{fontSize:'15px'}}  className={'mt-2 mb-1'}>{mentor.firstName} {' '}
      {mentor.profileComplete ? <>
        <img style={{ width: '10%', verticalAlign: 'text-bottomsss' }} data-tip data-for="verificationTip" src={verifiedLogo} alt="Verified" />
        <ReactTooltip id="verificationTip" place="top" effect="solid">
          This user has completed their NXSTEP profile. Their compatibilty ranking is more accurate.
        </ReactTooltip>
      </> : <></>}</Card.Text>

    <Card.Text style={{fontSize:'13px'}} className={'mb-2 green'}><span className='d-block'> <FaStar/> ({mentor.rating && mentor.rating.toFixed(1)})</span></Card.Text>
      <Card.Text style={{fontSize:'13px'}} className='trunc mb-2'><IoMdSchool color='#7678ed'/> {' '}{mentor.collegeName || '-'}</Card.Text>
    <Tile width={'100%'} fontSize={'0.8em'} color={'green'}>{majors[mentor.major] || 'Unknown'}</Tile>
    <div>
    {props.children}
    </div>

    {connection ? <>
      <div style={{justifyContent: 'center'}}>
        <div style={{marginTop: '30px', cursor: 'pointer'}}>
          <Tile onClick={() => props.respondToConnection('ACCEPTED', connection)} marginBottom={'20px'} width={'100%'} fontSize={'0.8em'} color={'purple'}>Accept</Tile>
        </div>
        <div style={{marginTop: '5px', cursor: 'pointer'}}>
          <Tile onClick={() => props.respondToConnection('REJECTED', connection)} width={'100%'} fontSize={'0.8em'} color={'red'}>Reject</Tile>
        </div>
      </div>
        </> : <></>}
    </Card.Body>
  </Card>
};
