import axios from 'axios'
import * as vars from './vars';

// from: userId, to: userId, startTime: long, endTime: long, read: boolean
export function getAllMessages (queryParams){
    const params = new URLSearchParams(queryParams)
    return axios({
        method: 'GET',
        url: vars.baseURL + '/api/v2/messaging/messages?' + params,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: {}
    })
  }

export function getMessage (messageId){
    return axios({
        method: 'GET',
        url: vars.baseURL + `/api/v2/messaging/messages/${messageId}`,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: {}
    })
}

// participantId: userId, startTime: long, endTime: long
export function getThreads (queryParams){
    const params = new URLSearchParams(queryParams)
    return axios({
        method: 'GET',
        url: vars.baseURL + `/api/v2/messaging/threads?` + params,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: {}
    })
}

export function getThread (threadId){
    return axios({
        method: 'GET',
        url: vars.baseURL + `/api/v2/messaging/threads/${threadId} `,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: {}
    })
}

export function getAiChatThread (userId){
    const params = new URLSearchParams({userId: userId})
    return axios({
        method: 'GET',
        url: vars.baseURL + `/api/v2/messaging/ai?${params}`,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: {userId: userId}
    })
}

export function readAIChat (userId){
    return axios({
        method: 'PATCH',
        url: vars.baseURL + `/api/v2/messaging/ai`,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: {userId: userId}
    })
}

export function askAI (userId, message, collegeName = undefined, ipedsid = undefined){
    const data = {userId: userId, message: message}

    if (collegeName) {
        data['collegeName'] = collegeName;
    }

    if (ipedsid) {
        data['ipedsid'] = ipedsid
    }

    return axios({
        method: 'POST',
        url: vars.baseURL + `/api/v2/messaging/ai`,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: data
    })
}

export function getUnreadMessageCount(userId) {
  const params = new URLSearchParams({status: 'sent', userId: userId})
  return axios({
      method: 'GET',
      url: vars.baseURL + '/api/v2/messaging/count' + "?" + params,
      headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
      data: {}
  })
}

export function markThreadAsRead (threadId, participantId){
    return axios({
        method: 'PATCH',
        url: vars.baseURL + `/api/v2/messaging/threads/${threadId} `,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: {status: 'read', participantId: participantId}
    })
}

export function archiveThread (threadId, participantId){
    return axios({
        method: 'PATCH',
        url: vars.baseURL + `/api/v2/messaging/threads/${threadId} `,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: {archived: true, participantId: participantId}
    })
}

// data = {
//     participantIds: [userId, userId],
//     senderId: userId,
//     subject: String,
//     message: String,
//     messageType: [“Audio”, “Text”, “Image”, “Video”, “File”]
// }
export function createThread (data){
    return axios({
        method: 'POST',
        url: vars.baseURL + '/api/v2/messaging/threads',
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: data
    })
  }

//   data = {
//     senderId: userId,
//     subject: String,
//     message: String,
//     messageType: [“Audio”, “Text”, “Image”, “Video”, “File”]
//     }
export function sendMessage (data, threadId){
    return axios({
        method: 'POST',
        url: vars.baseURL + `/api/v2/messaging/threads/${threadId}`,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
        data: data
    })
}
