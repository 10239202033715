import React, {useState, useEffect, useRef} from 'react';
import { NavBar } from 'Components/Nav/NavBar';
import Button from 'react-bootstrap/Button';
import Footer from './Footer';
import s from './styles/Landing.module.scss';
import { Root } from 'Root';
import h from './styles/HowItWorks.module.scss';
import Card from 'react-bootstrap/Card';
import Zoom from 'react-reveal/Zoom'
import Fade from 'react-reveal/Fade'
import Pulse from 'react-reveal/Pulse';

import step_1 from './static/step_1.png'
import step_2 from './static/step_2.png'
import step_3 from './static/step_3.png'
import step_4 from './static/step_4.png'
import step_5 from './static/step_5.png'
import num_1 from './static/num_1.svg'
import num_2 from './static/num_2.svg'
import num_3 from './static/num_3.svg'
import num_4 from './static/num_4.svg'
import num_5 from './static/num_5.svg'
import chase_logo from './static/chase_logo.svg'
import infinity_logo from './static/infinity_logo.svg'
import amazon_logo from './static/amazon_logo.png'
import microsoft_logo from './static/microsoft_logo.svg'
import apple_logo from './static/apple_logo.svg'
import landing_img_1 from './static/landing.svg'
import background from './static/employerbg.svg'


const Landing = () => {
  const [direct, setDirect] = useState(true)
  const [mentorCount, setMentorCount] = useState(null)
  const [collegeRepCount, setCollegeRepCount] = useState(null)


  useEffect(() => {
    Root.collegeApi.getMentorCount().then(rsp => {
        setMentorCount(Math.round(rsp.data / 50) * 50)
    }).catch(error => {
      console.log(error)})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    Root.collegeApi.getRepresentedCollegesCount().then(rsp => {
        setCollegeRepCount(Math.round(rsp.data / 50) * 50)
    }).catch(error => {
      console.log(error)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <>
    <div className={s.landing}>
      <div
      className={s.sectionOne}
      style={{ backgroundImage: `url(${background})`,
               backgroundPosition: "center",
               backgroundSize: "cover",
               backgroundRepeat: "no-repeat"
             }}>
            <NavBar className={s.nav}/>
            <div className={``}>
              <div className={s.grid}>
              <Fade left>
                <span>
                    <h1>
                      <strong>Seamlessly Discover and Hire Student Talent For Your <span style={{color:'#8EE3C8'}}>Short-Term Business Needs.</span></strong>
                    </h1>

                    <h2 className={s.subtitle}>
                      Want us to recruit vetted talent for you? Send us an inquiry <a style={{color:'white'}} href="/contact">here.</a>
                    </h2>

                    <div className={s.action}>
                      <Button className={`btnFill lg px-5 dark ${s.join}`} href='/register/company'>Hire Talent</Button>
                      <Button className={`btnFill lg px-5 green ms-3 ${s.join}`} href='/jobs'>Get Hired</Button>
                    </div>
                  </span>
            </Fade>
                  <Fade up>
                  <div className={s.image}>
                    <img style={{width:'90%', maxWidth: '700px',}} src={landing_img_1}/>
                  </div>
                  </Fade>
              </div>
              <p className='text-center fs-18 mb-4' style={{color:'white'}}>Collective Talent Experience From...</p>
              <div className="d-flex justify-content-evenly align-items-center" style={{maxWidth:'90%', margin: '0 auto', overflow:'scroll'}}>
                <img src={amazon_logo}/>
                <img src={apple_logo}/>
                <img src={microsoft_logo}/>
                <img src={infinity_logo}/>
                <img src={chase_logo}/>
              </div>
            </div>
      </div>
      <div className={s.sectionTwo}>
        <div className={s.container}>
          <h2>How it Works</h2>
          <Zoom>
          <div className='d-flex justify-content-center my-5'>
            <Button onClick={()=>{setDirect(!direct)}} className={`btnFill lg mx-1 ${direct ? 'orange' : s.inactive}`}>Direct Hiring</Button>
            <Button onClick={()=>{setDirect(!direct)}}  className={`btnFill lg mx-1 ${direct ? s.inactive: 'orange'}`}>Concierge Hiring</Button>
          </div>
          </Zoom>

        <div className={s.stepContainer}>
        <Fade up>
              <span className={`${s.step} ${!direct && s.concierge}`}>
                <img src={num_1}/>
                <span>
                    <h4>{direct ? 'Post A Job' : 'Tell Us What You Need'}</h4>
                    <p>{direct ? "Once you sign-up, you can post a project at the click of a button. We regularly push newly posted projects out to students via our Newsletter. Note: You may also invite students to apply via the ‘Recruit’ tab." :
                    "In order to find the best fit for your project, we have to understand what your budget is, what you need,  and when you need it! That’s why we ask you to contact us "}<a href="/contact">here.</a></p>
                </span>
              </span>
        </Fade>
        <Fade right>
              {direct && <span className="d-flex justify-content-center"><img src={step_1} width='354px'/></span>}
        </Fade>
        </div>

        <div className={s.stepContainer}>
          <Fade up>
            <span className={`${s.step} ${!direct && s.concierge}`}>
            <img src={num_2}/>
            <span>
            <h4>{direct ? 'View Applications' : 'We’ll Reach Out'}</h4>
            <p>{direct ? "To access and sort through applications, click on the ‘Applications’ Tab. From a student’s application, you can view their resume, and answers to any screening questions you may have asked." :
            "After you’ve given us the information we need, we’ll reach out and confirm that you’re all set. We may ask for additional details to better understand the project but after that..sit back, relax and let us do the work!" }</p>
            </span>
            </span>
          </Fade>
          <Fade right>
            {direct && <span className="d-flex justify-content-center"><img src={step_2} width='354px'/></span>}
          </Fade>
        </div>

        <div className={s.stepContainer}>
          <Fade up>
            <span className={`${s.step} ${!direct && s.concierge}`}>
                <img src={num_3}/>
                <span>
                <h4>{direct ? 'Screen Applicants' : 'We’ll Find The Best Fit'}</h4>
                <p>{direct ? "If you want to contact/know more about a student in addition to what’s in their application, you can click “View Profile” from their application. From their profile, you can message them and view their skills, interests, and extracurriculars." :
                    "We’ll screen through thousands of highly-qualified and vetted student talent to find the one(s) that best suits your specific needs. We’ll then provide you with the profile of the top pick(s) and schedule a preliminary meeting between you and the talent *once you give the green light*."
                }</p>
                </span>
            </span>
          </Fade>
          <Fade right>
            {direct && <span className="d-flex justify-content-center"><img src={step_3} width='354px'/></span>}
          </Fade>
        </div>

        <div className={s.stepContainer}>
        <Fade up>
        <span className={`${s.step} ${!direct && s.concierge}`}>
                    <img src={num_4}/>
                    <span>
                    <h4>{direct ? 'Hire Applicant' : 'Match Found!'}</h4>
                    <p>{direct ? "Upon viewing an application, you should change the application status to “Reviewing”, “Rejected”, or “Hired”. Although communication can take place off-platform, payment must take place through NXSTEP per our T&C." :
                    "Once we have found a match for your specified project, we’ll put them in contact with you and let you take it from there. If, for some reason, things don’t work out- the contract can be cancelled at any time."
                    }</p>
                    </span>
                </span>
        </Fade>
        <Fade right>
                {direct && <span className="d-flex justify-content-center"><img src={step_4} width='354px'/></span>}
        </Fade>
        </div>

        <div className={s.stepContainer}>
        <Fade up>
            <span className={`${s.step} ${!direct && s.concierge}`}>
                <img src={num_5}/>
                <span>
                    <h4>{direct ? 'Pay Student' : 'Payment'}</h4>
                    <p>{direct ? "It is the job of the student to request payment on a scheduled decided between you and said student. You can fulfill or dispute the payment request via the ‘Payment’ tab. We only charge a 5% service fee per project transaction." :
                      "A payment schedule must be decided between you and the talent. You will be invoiced directly from NXSTEP for the talent's work (according to the decided upon the payment schedule)."
                    }</p>
                </span>
            </span>
          </Fade>
          <Fade right>
            {direct && <span className="d-flex justify-content-center"><img src={step_5} width='354px'/></span>}
          </Fade>
        </div>

        <Zoom>
        <div className="d-flex justify-content-center">
          <Button className="btnFill md mt-3 green" href={direct ? '/register/company' : '/contact'}>Get Started</Button>
        </div>
        </Zoom>
        </div>
      </div>
    <Fade up>
      <div className={`${s.sectionTwo} ${h.partner}`}>
        <h2 className='text-center my-5'>Why NXSTEP</h2>
        <div className={h.gridThreeCol}>
        <Card style={{flex:1, backgroundColor:'#22388C'}} className={h.card}>
          <Card.Body>
            <Card.Title style={{
              fontSize:'24px',
              display: 'flex',
              justifyContent:'center',
              alignItems: 'center'
            }}><strong>Great Value</strong></Card.Title>
            <Card.Text className='mt-4' style={{lineHeight:'30px', fontSize:'16px',}}>
            Work with highly motivated and enthusiastic freelancers who want nothing else but to leave the best impression for their clients while building their resume in the process- all at a price point that's right for your company.
            </Card.Text>
          </Card.Body>
        </Card>
        <Card className={h.card}>
          <Card.Body>
            <Card.Title style={{
              fontSize:'24px',
              display: 'flex',
              justifyContent:'center',
              alignItems: 'center'
            }}><strong>Streamlined Hiring</strong></Card.Title>
            <Card.Text className='mt-4' style={{lineHeight:'30px', fontSize:'16px',}}>
            From posting a project to accepting applications in just a few days, NXSTEP provides you with everything necessary to seamlessly scout, interview and communicate with your new hire every step of the way            </Card.Text>
          </Card.Body>
        </Card>
        <Card style={{flex:1, backgroundColor:'#05AF8C'}} className={h.card}>
          <Card.Body>
            <Card.Title style={{
              fontSize:'24px',
              display: 'flex',
              justifyContent:'center',
              alignItems: 'center'
            }}><strong>Flexible Contracts</strong></Card.Title>
            <Card.Text className='mt-4' style={{lineHeight:'30px', fontSize:'16px',}}>
            Whether you're looking to pay your hire a fixed payment, commission, or on an hourly basis- We support all kinds of flexible and remote contracts to suit all kinds of needs
            </Card.Text>
        </Card.Body>
        </Card>
      </div>
    </div>
    </Fade>

    <Fade>
      <div className={`${s.sectionSix} ${s.preFooter}`}>
        <div className={s.grid}>
          <div><img style={{width:'100%'}}  src={'https://cdn.cassy.dev/landing_laptop_mockup.svg'}/></div>
          <div>
            <h4>
            Want us do the recruiting for you?
              <br />
              {' '}
              <span className="green">No Problem!</span>
            </h4>
            <p>
            We know that not everyone has the time to post a project, look through applications, screen applicants, find the right fit, etc. Fortunately, we can do all of this for you!             </p>
            <p>
            Simply contact us and we’ll have a representative reach out shortly after to gauge the scope of your project and the skills necessary to ensure successful completion of the job.            </p>
            <Button className="btnFill md mt-3 orange" href='/contact'>Recruit for Me</Button>
          </div>
        </div>
      </div>
    </Fade>
    </div>
    <Footer />
  </>
};

export default (Landing);
