import React, { useState, useEffect, useRef } from 'react'
import { Root } from 'Root'
import Button from 'react-bootstrap/Button'
import Dropdown from 'react-bootstrap/Dropdown'
import { BsFilter} from 'react-icons/bs'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import { Typeahead } from 'react-bootstrap-typeahead'
import { collegeIds } from 'Utils/colleges'
import s from './styles/Search.module.scss'
import { TextInput } from 'Input/Input';
import { AiOutlineSearch } from 'react-icons/ai'

export const SearchBar = ({className, containerClassName, height, placeholder}) => {
    const [query, setQuery] = useState(null);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [suggestions, setSuggestions] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
          // Replace this with your actual API call or data fetching logic
          if (query?.length > 0) {
            setSuggestions(filterSchools(query));
          } else {
            setSuggestions([]);
          }
        };

        fetchData();
      }, [query]);

      function filterSchools(searchTerm) {
        // Convert searchTerm to lowercase for case-insensitive matching
        const lowerCaseSearchTerm = searchTerm.toLowerCase();

        // Filter strings based on relevance
        const filteredStrings = collegeIds
          .filter(college => college.label.toLowerCase().includes(lowerCaseSearchTerm)) // Filter by inclusion
          .sort((a, b) => {
            // Calculate relevance score (simple example)
            const aScore = calculateRelevanceScore(a.label, lowerCaseSearchTerm);
            const bScore = calculateRelevanceScore(b.label, lowerCaseSearchTerm);
            return bScore - aScore; // Sort in descending order of relevance
          }).map(college => college.label);

          if (filteredStrings.length > 8) {
            return filteredStrings.slice(0, 7)
          }

          return filteredStrings;
        }

      function calculateRelevanceScore(str, searchTerm) {
        // Simple relevance score:
        // - Higher score for exact matches at the beginning
        // - Lower score for partial matches or matches later in the string
        if (str.toLowerCase().startsWith(searchTerm)) {
          return 10;
        } else if (str.toLowerCase().includes(searchTerm)) {
          return 5;
        } else {
          return 0;
        }
      }

      function useSuggestion(suggestion) {
        setQuery(suggestion);
        const params = new URLSearchParams({
          query: suggestion,
        })
        window.location.href = '/search?' + params
      }

      function handleRedirect () {
        if (query && query.length > 0) {
          const params = new URLSearchParams({
            query: query,
          })
          window.location.href = '/search?' + params
        }
      }

    return <div className={`${containerClassName}`}>
        <InputGroup>
          <FormControl autoComplete="off" style={{height:height}}  maxLength={"255"} placeholder={placeholder || "Search a school or student..."} className={`${className}`} value={query || ''} name={"text"} onChange={(e)=>{
            setShowSuggestions(true);
            setQuery(e.target.value);
          }}/>
          <Button style={{height:height, borderTopRightRadius: '10px', borderBottomRightRadius: '10px'}} className='btnFill green' onClick={handleRedirect}>
            <AiOutlineSearch/>
          </Button>
        </InputGroup>
        {showSuggestions && suggestions.length > 0 && ( // Conditionally render suggestions list
          <ul className={`${s.suggestions}`}>
            {suggestions.map((suggestion, index) => (
              <li key={index}>
                <Button className='btn-light' style={{backgroundColor: 'white', border: 'none', width: '100%'}} onClick={() => {useSuggestion(suggestion);}}>
                  {suggestion}
                </Button>
              </li>
            ))}
          </ul>
        )}
      {/* <InputGroup className={`${className}`}>
    <Dropdown>
        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
            <Button style={{height:height}} className={s.filter}><BsFilter/></Button>
        </Dropdown.Toggle>
        <Dropdown.Menu>
            <Dropdown.Item style={schoolsWithMentors ? { backgroundColor: 'lightgrey' } : {}} onClick={handleFilter}>Schools with Mentors</Dropdown.Item>
            <Dropdown.Item style={!schoolsWithMentors ? { backgroundColor: 'lightgrey' } : {}} onClick={handleFilter}>All Schools</Dropdown.Item>
        </Dropdown.Menu>
    </Dropdown>
    <Typeahead style={{height:height}} className={s.search} options={getOptions()} ref={typeahead}  errors={{}} name={'college'}
    placeholder={'Search a school...'} id={'college'} onChange={handleRedirect}/>
</InputGroup> */}
</div>
}
