import React, {useState, useEffect} from 'react';
import { NavBar } from 'Components/Nav/NavBar';
import Button from 'react-bootstrap/Button';
import {SearchBar} from 'Components/Input/Search';
import { FiSearch } from 'react-icons/fi';
import Footer from './Footer';
import s from './styles/CollegeLanding.module.scss';
import { Root } from 'Root';
import {ImQuotesLeft} from 'react-icons/im'
import {FaStar, FaArrowRight, FaArrowLeft, FaGraduationCap} from 'react-icons/fa'
import Fade from 'react-reveal/Fade'
import Zoom from 'react-reveal/Zoom'

import step_1 from './static/step_1.svg'
import step_2 from './static/step_2.svg'
import step_3 from './static/step_3.svg'
import step_4 from './static/step_4.svg'
import num_1 from './static/num_1.svg'
import num_2 from './static/num_2.svg'
import num_3 from './static/num_3.svg'
import num_4 from './static/num_4.svg'
import arrow_icon from './static/arrow_icon.svg'
import exit_icon from './static/exit_icon.svg'
import sad_icon from './static/sad_icon.svg'
import heading from './static/heading.svg'
import landing_img_2 from './static/landing.svg'
import hero from './static/hero.svg'
import background from './static/collegebg.svg'

const Landing = () => {
  const [applicant,setApplicant] = useState(true)
  const [mentorCount, setMentorCount] = useState(null)
  const [collegeRepCount, setCollegeRepCount] = useState(null)

  var testimonyIndex = 0;
  const testimonies = {
    0: {quote: 'I definitely have a much more transparent view of the school... the social scene, type of food, dorm life and all that than I did before, even after touring the school.', author: 'Parker K.'},
    1: {quote: 'I definitely have a much more transparent view of the school... the social scene, type of food, dorm life and all that than I did before, even after touring the school.', author: 'Parker K.'},
    2: {quote: 'I definitely have a much more transparent view of the school... the social scene, type of food, dorm life and all that than I did before, even after touring the school.', author: 'Parker K.'}
  }

  useEffect(() => {
    Root.collegeApi.getMentorCount().then(rsp => {
        setMentorCount(Math.round(rsp.data / 50) * 50)
    }).catch(error => {
      console.log(error)})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    Root.collegeApi.getRepresentedCollegesCount().then(rsp => {
        setCollegeRepCount(Math.round(rsp.data / 50) * 50)
    }).catch(error => {
      console.log(error)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <>
    <div className={s.landing}>
      <div
      className={s.sectionOne}
      style={{ backgroundImage: `url(${background})`,
               backgroundPosition: "center",
               backgroundSize: "cover",
               backgroundRepeat: "no-repeat",
             }}>
            <NavBar className={s.nav}/>
            <div className={``}>
              <div className={s.grid}>
              <Fade left>
                <span className={s.hero}>
                    <h1>
                      <strong>Get Help For Where You're Going With <span style={{color:'#8EE3C8'}}>NXSTEP</span></strong>
                    </h1>

                    <h2 className={s.subtitle}>
                    Explore, discover, and learn more about universities, employment opportunities, and more through content sharing, peer-to-peer chats, & trusted reviews
                    </h2>

                    <div className={s.searchContainer}>
                      <SearchBar height={'50px'} color="green" text={<FiSearch />} placeholder="Search for a college or major" />
                      <Button className={`btnFill md dark mt-3 ${s.join}`} href='/register'>Join the NXSTEP Community</Button>
                    </div>
                  </span>
              </Fade>
                  <Fade down>
                  <div className={s.image}>
                    <img style={{width:'85%', maxWidth: '700px'}} src={hero}/>
                  </div>
                  </Fade>
              </div>

              <div className={s.stats}>
                <div className={s.stat}>
                  <p>{collegeRepCount || "6000"} +</p>
                  <p>Colleges Represented</p>
                </div>
                {/*
                  <div className={s.stat}>
                    <p>{mentorCount || "3100"} +</p>
                    <p>College Students</p>
                  </div>
                 */}
              </div>
            </div>
      </div>
      <div className={s.sectionTwo}>
        <div className={s.container}>
          <h2>How it Works</h2>
          <div className='d-flex justify-content-center mt-5'>
            <Button onClick={()=>{setApplicant(!applicant)}} className={`btnFill lg mx-1 ${applicant ? 'orange' : s.inactive}`}>College Applicants</Button>
            <Button onClick={()=>{setApplicant(!applicant)}}  className={`btnFill lg mx-1 ${applicant ? s.inactive: 'orange'}`}>College Students</Button>
          </div>
          <div className={s.gridContainer}>
            <div className={s.grid}>
              <span className={s.step}>
                <img src={num_1}/>
                <span>
                  <h4>Let us get to know you</h4>
                  <p>Start by filling out your optional NXSTEP profile, so we can tailor your NXSTEP experience to you.</p>
                </span>
              </span>
              <span><img src={step_1}/></span>
            </div>
            <div className={s.grid}>
              <span className={`${s.stagger}`}>
                  <img src={num_2}/>
                  <span>
                    <h4>{applicant ? 'Search' : 'Explore'}</h4>
                    <p>{applicant ? "Choose from 5000+ universities across the US." :
                  "Get booked for a NXSTEP Meeting or start exploring internship opportunities!" }</p>
                  </span>
                </span>
                <span><img src={step_2}/></span>
            </div>
            <div className={s.grid}>
              <span className={`${s.step}`}>
                    <img src={num_3}/>
                    <span>
                    <h4>{applicant ? 'Explore a University' : 'NXSTEP Meetings'}</h4>
                    <p>{applicant ? "Explore student-uploaded content, create/join groups, and schedule chats with college students who go there." :
                      "College applicants may use NXSTEP to schedule (optional) meetings with college students so they can learn more about a school. Upon meeting completion, college students receive pay."
                    }</p>
                  </span>
                </span>
                <span><img src={step_3}/></span>
            </div>
            <div className={s.grid}>
             <span className={`${s.stagger}`}>
                    <img src={num_4}/>
                    <span>
                    <h4>{applicant ? 'Ask Away' : 'NXSTEP Internships'}</h4>
                    <p>{applicant ? "If you choose to schedule a chat with a college student- we encourage you to get the most from it and ask your most pressing questions!" :
                      "NXSTEP Internships allows students to browse entry-level jobs that aligns with their major, goals & interests. Upon filling out your profile, you’ll be able to instantly apply to unique internship opportunities at the click of a button!"
                    }</p>
                    </span>
                </span>
                <span><img src={step_4}/></span>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <Button className="btnOutline mt-5 mb-5 lg" href='/about'>See More About How it Works</Button>
          </div>
        </div>
      </div>

      <div className={s.sectionThree}>
        <div className={`${s.container} ${s.grid}`}>
          <div>
           <h3 className='text-muted'><strong>College Applicants</strong></h3>
           <h2>Choosing the right <br/> school is crucial</h2>
            <h3 className='mt-3'>As the standard college process is flawed</h3>
            <div className='mt-5'>
              <span className={`${s.stat} d-flex`}>
                <img style={{height:'70px'}} className='px-2' src={arrow_icon}/>
                <span>
                  <p>35%</p>
                  <p>of college students transfer at least once</p>
                </span>
              </span>
            </div>
            <div>
              <span className={`${s.stat} d-flex`}>
                <img style={{height:'70px'}} className='px-2' src={exit_icon}/>
                <span>
                  <p>30%</p>
                  <p>of college students drop out their first year</p>
                </span>
              </span>
            </div>
            <div>
              <span className={`${s.stat} d-flex`}>
                <img style={{height:'70px'}} className='px-2' src={sad_icon}/>
                <span>
                  <p>27%</p>
                  <p>show an increase in symptoms of depression when the wrong college is chosen</p>
                </span>
              </span>
            </div>
            <Button className='btnOutline orange md mt-5' href='/searc' >Begin your search with NXSTEP</Button>
          </div>
          <div><img style={{width:'100%'}} className='mt-5' src={'https://cdn.cassy.dev/landing_phone_mockup.svg'}/></div>
        </div>

        <div className={`${s.container} ${s.grid}`}>
          <div>
           <h3 className='text-muted'><strong>College Students</strong></h3>
          <h2 className='mb-5'>Build your resume with unique internship opportunities</h2>
          <p>NXSTEP works closely with unique and fast-growing companies & start-ups to provide you with the opportunity to be a part of something with great potential...all while building your resume!</p>
          <p>Once your NXSTEP Profile is all set up, you will immediately be able to discover, filter, and apply to open positions with the click of a button.</p>
          <Button className='btnOutline md orange my-4' href='/register'>Sign Up as College Student</Button>
          </div>
          <div><img style={{width:'100%'}} src={'https://cdn.cassy.dev/landing_laptop_mockup.svg'}/></div>
        </div>

        <div className={`${s.container} ${s.grid}`}>
          <div>
           <h3 className='text-muted'><strong>College Students</strong></h3>
          <h2 className='mb-5'>Upload videos, pictures, blogs, and more for pay</h2>
          <p>Help applicants learn more about your University by uploading videos of your college life, pictures, blogs, college essays, and/or more regarding your school life</p>
          <p>By uploading content, you boost your chances of getting matched with applicants for paid NXSTEP Meetings as well as companies for internships in the future.</p>
          <Button className='btnOutline md orange my-4' href='/register'>Sign Up as College Student</Button>
          </div>
          <div><img className={s.img_2} src={'https://cdn.cassy.dev/landing_blog.jpg'}/></div>
        </div>
      </div>

      <div className={s.sectionFour}>
        <div className={s.container}>
          <h2>Trending Colleges</h2>
          <div className={s.grid}>
            <div className='mb-4' className={s.uniTile}>
              <img src={"https://lh3.googleusercontent.com/p/AF1QipMs-IWz0qatxsfHebnM9WRLmKS_bcQpwVaH04ab=s1600"}/>
              <p className='text-start mb-0'>University of Notre Dame</p>
              <p className='text-start mb-0'>South Bend, IN</p>
              <div className='d-flex justify-content-between mb-3'>
                <span className='d-flex align-items-center'><FaStar style={{marginRight:'5px'}} fill='#F45728'/>{' '}4.2</span>
                <Button className='btnOutline' href='/university?name=University+of+Notre+Dame&id=152080'><FaArrowRight/></Button>
              </div>
            </div>
            <div className='mb-4' className={s.uniTile}>
              <img  src="https://lh3.googleusercontent.com/p/AF1QipNZ8ubqe03uqX_i_oYdvHpfbqRqfjggZPyMXBse=s1600"/>
              <p className='text-start mb-0'>University of California-Berkeley</p>
              <p className='text-start mb-0'>Berkeley, CA</p>
              <div className='d-flex justify-content-between mb-3'>
                <span className='d-flex align-items-center'><FaStar style={{marginRight:'5px'}} fill='#F45728'/>{' '}4.0</span>
                <Button className='btnOutline' href='/university?name=University+of+California-Berkeley&id=110635'><FaArrowRight/></Button>
              </div>
            </div>
            <div className='mb-4' className={s.uniTile}>
              <img src={"https://lh3.googleusercontent.com/p/AF1QipOA55HwMxQPJigBkFZBi4ki1d7MYU80ThUZRgn8=s1600"}/>
              <p className='text-start mb-0'>University of California-Irvine</p>
              <p className='text-start mb-0'>Irvine, CA</p>
              <div className='d-flex justify-content-between'>
                  <span className='d-flex align-items-center'><FaStar style={{marginRight:'5px'}} fill='#F45728'/>{' '}4.4</span>
                  <Button className='btnOutline' href='/university?name=University+of+California-Irvine&id=110653'><FaArrowRight/></Button>
              </div>
            </div>
          </div>
          <Button className={`btnOutline md mx-auto d-block orange ${s.discover}`} href='/search'>Discover All Colleges</Button>
        </div>
      </div>

      <div className={s.sectionFive}>
        <div>
          <div>
            <h2>Testimonials</h2>
            <h3>NXSTEP has aided hundred of students in finding their preferred colleges</h3>
          </div>
          <div className={s.grid}>
            <div><img src={'https://cdn.cassy.dev/testimony_group.svg'} style={{maxWidth:'100%'}}/></div>
            <div className={s.testimonyCarousel}>
              <div className={s.testimonyArrow}>
                <Button className={`btnOutline md mx-auto d-block orange ${s.discover}`} onClick={() => testimonyIndex-- }><FaArrowLeft/></Button>
              </div>
              <div className={`p-relative mx-auto ${s.testimonyArrow}`} style={{maxWidth:'70%'}}>
                    <ImQuotesLeft className='d-block' color='white' size={35}/>
                    <p className={s.quote}>{testimonies[testimonyIndex % 3].quote}</p>
                    <p style={{color:'white'}}><strong>{testimonies[testimonyIndex % 3].author}</strong></p>
              </div>
              <div className={s.testimonyArrow}>
                <Button className={`btnOutline md mx-auto d-block orange ${s.discover}`} onClick={() => testimonyIndex++ }><FaArrowRight/></Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </>
};

export default (Landing);
