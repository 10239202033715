import React, {useState, useEffect, useContext} from 'react'
import { useLocation } from 'react-router-dom';
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import Button from 'react-bootstrap/Button'
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import {RiNotification2Fill, RiNotification2Line} from 'react-icons/ri'
import Spinner from 'react-bootstrap/esm/Spinner'
import {Root} from 'Root'
import { SessionContext, isStudent } from 'Utils/sessions';
import { Link } from 'react-router-dom'
import { timestampToDiff } from 'Utils/time';
import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const ImportantNotificationPopup = ({notification, session, ...props}) => {

  const onHide = () => {
    Root.userApi.markNotificationRead(session.id, notification.id).then(() => {
      fetchNotifs();
    }).catch(err => console.log(err))

    props.setShowImportantNotification(false);
  }

  function markAsRead(id) {
    onHide();
  }

  return (
    <Modal show={true} onHide={onHide}>
    <ModalHeader closeButton>
    <Modal.Title>{notification.subject || 'Important Notification'}</Modal.Title>
    </ModalHeader>
    <Modal.Body>
    <p>{notification.message}</p>
    {notification.link && <div>
      <Link className='btnLink fs-13' onClick={() => { markAsRead(notification.id); }} to={notification.link}>{notification.action || 'Click to view'}</Link>
      </div>}
      {!notification.link && <div>
        <Link className='btnLink fs-13' onClick={() => { markAsRead(notification.id); }}>Dismiss</Link>
        </div>}
        </Modal.Body>
        </Modal>
      )
    }

    const Notifications = ({className}) => {
      const shouldDisplayInAppNotification = !useLocation().pathname.includes('/inbox');
      const {session, refreshSession} =  useContext(SessionContext);
      const [notifs, setNotifs] = useState(null)
      const [error, setError] = useState(null)
      const [importantNotification, setImportantNotification] = useState(null)
      const [showImportantNotification, setShowImportantNotification] = useState(false)
      const [registration, setRegistration] = useState(null);
      const [notificationsPermitted, setNotificationsPermitted] = useState(false);
      const [notificationToken, setNotificationToken] = useState(localStorage.getItem('notificationToken') || '');
      const [deviceId, setDeviceId] = useState(localStorage.getItem('deviceId') || `${crypto.randomUUID()}`);
      const [getPermissions, setGetPermissions] = useState(false)

      useEffect(() => {
        navigator.serviceWorker.addEventListener('message', event => {
          if (event.data.type === 'notification-received') {
            const notificationData = event.data.notificationData;
            if (shouldDisplayInAppNotification) {
              toast("New message! Go to your inbox to view more.", {
                position: toast.POSITION.TOP_CENTER,
              });
            }
            fetchNotifs();
            // Create a new CustomEvent. The first argument is the event name.
            const myEvent = new CustomEvent('new-notification-received', {threadId: notificationData.resourceId});
            // Dispatch the event on the document:
            document.dispatchEvent(myEvent);
          }
        });

        return () => {
          navigator.serviceWorker.removeEventListener('message', handleMessage);
        };
      }, []);

      useEffect(() => {
        document.addEventListener('thread-marked-read', (event) => {
          fetchNotifs();
        });
      }, []);

      const handleMessage = (event) => {
        if (event.data.type === 'push-received') {
          console.log('Message from service worker:', event.data.message);
          // Perform actions in your app based on the message
          alert(event.data.message)
        }
      }

      useEffect(() => {
        // Check for service worker support
        if ('serviceWorker' in navigator) {
          navigator.serviceWorker.register('/service-worker.js') // Path to your service worker file
          .then(serviceWorkerRegistration => {
            if (serviceWorkerRegistration) {
              setRegistration(serviceWorkerRegistration);
            }
          })
          .catch(err => console.error('Service Worker registration failed:', err));
        } else {
          console.error('Service Workers are not supported.');
        }
      }, []);

      useEffect(() => {
        // Check for notification support
        if (!("Notification" in window)) {
          console.log("This browser does not support notifications.");
          return;
        }

        // Request permission if not granted or denied
        if (Notification.permission === "default") {
          setGetPermissions(true);
        } else if (Notification.permission === "denied") {
          toast.error(
            <div>Notifications are currently blocked for this site. To enable them:<br></br>
              1. Click the lock icon in the address bar.<br></br>
              2. Find 'Notifications'.<br></br>
              3. Select 'Allow'.
            </div>, {enableHtml: true});
        } else {
          setNotificationsPermitted(true);
        }
      }, []);

      useEffect(() => {
        if (registration && notificationsPermitted) {
          registration.pushManager.getSubscription().then((subscription) => {
            if (subscription) {
              // already subbed
            } else {
              subscribeAndSave();
            }
          });
        }
      }, [registration, notificationsPermitted]);

      const requestNotificationPermission = async () => {
        try {
          await Notification.requestPermission();
          setGetPermissions(false);
          setNotificationsPermitted(true);
        } catch (error) {
          console.error("Error requesting notification permission:", error);
        }
      };

      const subscribeUserInChrome = async () => {
        const permissionState = Notification.permission
        if (permissionState === 'granted') {

        } else if (permissionState === 'denied') {
          // Display a message with instructions
          alert(
            "Notifications are currently blocked for this site. To enable them:\n\n" +
            "1. Click the lock icon in the address bar.\n" +
            "2. Find 'Notifications'.\n" +
            "3. Select 'Allow'."
          );
        }
      };

      const subscribeAndSave = async () => {
        if (!registration) return;

        const applicationServerPublicKey = urlBase64ToUint8Array("BG0ggoqUoVWU6Iwet3Mm35fPBLtv1M4YJGzhr2zQlqwKoJjEcgSxxiro-b3jWEPelDxjyCg50ntXUflXR9KxnW4"); // Convert VAPID key

        try {
          const subscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: applicationServerPublicKey
          }).catch((err) => console.log(`there's an error: ${err}`));
          if (!subscription) {
            return false
          }

          if (notificationToken === subscription.endpoint.replace("https://fcm.googleapis.com/fcm/send/", "")) {
            return;
          } else {
            if (notificationToken != '') {
              Root.userApi.unRegisterDeviceToken(session.id || session.userId, notificationToken, 'chrome');
            }

            const rawKey = subscription.getKey('p256dh');
            const p256dh = rawKey ? btoa(String.fromCharCode(...new Uint8Array(rawKey))) : null;
            const rawAuth = subscription.getKey('auth');
            const auth = rawAuth ? btoa(String.fromCharCode(...new Uint8Array(rawAuth))) : null;

            const token = {keys: {auth: auth, p256dh: p256dh}, endpoint: subscription.endpoint}

            const platform = 'chrome' in window ? 'Chrome' : 'Safari';

            // Send subscription to server
            Root.userApi.registerDeviceToken(session.id || session.userId, deviceId, JSON.stringify(token), platform)
            .then((res) => {
              if (res.status == 200) {
                const fbep = subscription.endpoint.replace("https://fcm.googleapis.com/fcm/send/", "");
                setNotificationToken(fbep);
                localStorage.setItem('notificationToken', fbep);
                localStorage.setItem('deviceId', deviceId);
              }
            })
            .catch(err => console.log(err));
          }
        } catch (error) {
          console.error('Failed to subscribe the user: ', error);
        }
      };

      useEffect(() => {
        if (!('Notification' in window)) {
          const sock = new SockJS(`${Root.baseURL()}/api/v2/notificationsocket`);
          const stompClient = Stomp.over(sock);
          stompClient.debug = function (){}; //do nothing
          const userId = session.id || session.userId
          stompClient.connect({}, (frame) => {
            stompClient.subscribe(`/messaging/${userId}/general-notifications`, (response) => {
              fetchNotifs();
            });
          });
        }
      }, [notifs]);


      // const actionItems = {
      //   ssn: {
      //       message: "In order to process bank account payments, it is legally required that you provide the last four digits of your SSN as well as your address. Follow the link to go to settings to enter your information",
      //       variant: "danger",
      //       link: "/settings",
      //       linkText: "Click Here",
      //       type: "important",
      //       subject: "Payment",
      //       timeOfCreation: Math.floor(Date.now() / 1000)
      //   },
      //   personalityProfile: {
      //       message: "Your NXSTEP profile has not been created! Follow the link to complete your profile.",
      //       variant: "warning",
      //       link: "/onboarding",
      //       linkText: "Click Here",
      //       type: "important",
      //       subject: "Update Your Account",
      //       action: "Complete Profile",
      //       link: "/profile/student/edit",
      //       timeOfCreation: Math.floor(Date.now() / 1000)
      //   },
      //   defaultAccount: {
      //       message: "A default payout method has not been set. Follow the link to go to settings to enter your information in order to recieve payments.",
      //       variant: "warning",
      //       type: "important",
      //       subject: "Payment",
      //       link: "/settings",
      //       linkText: "Click Here",
      //       timeOfCreation: Math.floor(Date.now() / 1000)
      //   },
      //   infoGuide: {
      //       message: "Please read the Information Guide to get an overview of how NXSTEP works!",
      //       variant: "warning",
      //       type: "important",
      //       subject: "Info Guide",
      //       link: "/info",
      //       linkText: "Click Here",
      //       timeOfCreation: Math.floor(Date.now() / 1000)
      //   },
      //   review: {
      //       message: "If you have not done so already, please take the time to rate your school! This helps us recommend schools for NXSTEP mentees.",
      //       variant: "normal",
      //       type: "message",
      //       subject: "University Rating",
      //       link: "",
      //       linkText: "Click Here",
      //       timeOfCreation: Math.floor(Date.now() / 1000)
      //   },
      // }

      // Utility function to convert VAPID key (from: https://web-push-book.gauntface.com/demos/common/js/base64-to-uint8array.js)
      function urlBase64ToUint8Array(base64String) {
        const padding = '='.repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');

        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);

        for (let i = 0; i < rawData.length; ++i) {
          outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
      }

      function sortMessages(messages){
        return messages.sort((a, b) =>
        a.dispatchTimestamp - b.dispatchTimestamp
      ).reverse()
    }

    function getSortedNotifs(notifications) {
      var messages = []
      messages.push(...notifications)
      // if (session.profile) {
      //   messages.push(actionItems.personalityProfile)
      // }
      return sortMessages(messages)
    }

    function fetchNotifs() {
      Root.userApi.getAllNotifications(session.id)
      .then((rsp) => {
        setNotifs(getSortedNotifs(rsp.data))
        detectImportantMessage(rsp.data)
      })
      .catch((err)=> {
        setImportantNotification(null);
        setShowImportantNotification(false)
        setError('Unable to refresh notifications')})
      }

      function pulseCheck() {
        Root.miscApi.serverPulseCheck()
        .then(() => {})
        .catch(()=> { toast.error("It looks like the server is restarting, please refresh in a few seconds!"); })
      }

      function detectImportantMessage(messages) {
        if (messages) {
          for (let i = 0; i < messages.length; i++) {
            if (messages[i].type && messages[i].type.toLowerCase() == 'important') {
              setImportantNotification(messages[i]);
              setShowImportantNotification(true)
              return;
            }
          }
        }
      }

      useEffect(()=>{
        pulseCheck()
        var messages = []
        // messages.push(actionItems.personalityProfile)
        setNotifs(messages)
        fetchNotifs()
      },[])

      const popover = (
        <Popover id="popover-basic">
        <Popover.Header as="h3">Notifications</Popover.Header>
        <Popover.Body style={{maxHeight:'500px', overflow:'scroll'}}>
        {notifs ?  <MessageTable session={session} messages={notifs} fetchNotifs={fetchNotifs}/> :
        <Spinner style={{color:"rgb(16, 142, 115)"}} animation="border" role="status">
        </Spinner>
      }
      {error && <p className='text-muted'>{error}</p>}
      </Popover.Body>
      </Popover>
    );

    return      <>
    <OverlayTrigger trigger="click" placement="left" overlay={popover}>
    <Button className={`btnLink ${className}`}><p style={{color:'#23CDAA', verticalAlign: 'sub'}}>{(notifs && notifs.length > 0) ? <RiNotification2Fill fill='#23CDAA' size='20'/> : <RiNotification2Line fill='#23CDAA' size='20'/>}{(notifs && notifs.length > 0) ? '('+ notifs.length + ')' : ''}</p></Button>
    </OverlayTrigger>
    {importantNotification && (showImportantNotification ?
      <>
      <ImportantNotificationPopup notification={importantNotification} session={session} setShowImportantNotification={setShowImportantNotification.bind(this)}/>
      </>
      : <></>)
    }

    {
      <Modal show={getPermissions} onHide={()=>setGetPermissions(false)}>
      <Modal.Header closeButton/>
      <Modal.Body>
      <h3>Permissions Needed</h3>
      <p>To receive notifications, you must first grant the necessary permissions.</p>
      <Button onClick={()=> { requestNotificationPermission() }} className='ms-2 btnLink'>Allow for notifications</Button>
      </Modal.Body>
      </Modal>
    }
    <ToastContainer theme="dark" autoClose={5000} position="bottom-center" />
    </>
  }

  export default (Notifications);


  const MessageTable = ({messages, session, fetchNotifs}) => {

    function markAsRead(id) {
      Root.userApi.markNotificationRead(session.id, id).then(() => {
        fetchNotifs();
      }).catch(err => console.log(err))
    }

    // // Types = ["BANNER", "MENTION", "important"]
    // function createPostLink(input, type){
    //   if(type === "important") {
    //     return input.link;
    //   }
    //
    //   var resource = JSON.parse(input);
    //   if (type.toUpperCase() === "MENTION") {
    //     var instution = "general"
    //     if(resource.institution && resource.institution.length > 1 ){
    //       instution = resource.institution
    //     }
    //     return `/forum/${instution}/${resource.root}`
    //   }
    //   if (type.toUpperCase() === "BANNER") {
    //     return `/${resource.institution}`
    //   }
    //   return null
    // }

    return (
      <>
      {messages && messages.length === 0 && <p className='fs-13 text-muted'>No notifications</p>}
      {messages.map((message, idx) => <> <div key={idx}>
      {!!message.subject ? <p style={{fontSize:'15px', fontWeight:'600', marginBottom:'5px'}}>{message.subject}</p> : <></>}
      <p style={{marginBottom:'5px'}}>{message.message}</p>
      <div style={{display:'flex', justifyContent: 'space-between', width: '100%'}}>
      {message.link &&
        <div>
        <Link className='btnLink fs-13' onClick={() => { markAsRead(message.id); }} to={message.link}>{message.action || 'Click to view'}</Link>
        </div>}
        {message.id &&
          <div style={{marginRight: '18px'}}>
          <Link className='btnLink fs-13 red' onClick={() => { markAsRead(message.id); }}>Dismiss</Link>
          </div>}
          </div>
          </div>
          <hr className='my-3' style={{backgroundColor:'black', height: 3}}/>
          </>)}
          </>
        );
      };
