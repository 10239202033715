import UserApi from './UserApi'
import CollegeApi from './CollegeApi'
import MiscApi from './MiscApi'
import CareerApi from './CareerApi'
import {getBaseURL} from './Utils/vars'

export const Root = {
    get userApi(){
        return UserApi;
    },
    get collegeApi(){
        return CollegeApi;
    },
    get miscApi(){
        return MiscApi;
    },
    get careerApi(){
        return CareerApi;
    },
    get baseURL() {
      return getBaseURL;
    }
};
